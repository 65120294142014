var bidclips_initialised;
var isAutoResize = false;
let existingMetaData;

function initBidClips(e) {
  if (bidclips_initialised === true) {
    return;
  }

  const $meta = document.createElement("meta");
  document.head.appendChild($meta);
  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    $meta.name = "viewport";
    $meta.content = "width=device-width, initial-scale=1.0, user-scalable=no";
  }
  // else if( /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
  //   this.alert('android');
  //   $meta.name = 'viewport';
  //   $meta.content = 'width=device-width, initial-scale=1.0, user-scalable=yes';
  // }
  // this.alert('browser');
  // $meta.name = 'viewport';
  // $meta.content = 'width=device-width, initial-scale=1.0, user-scalable=yes';

  if (window.location.href.includes("#bcwidget")) {
    var screenh = "500px";
    var screenw = "400px";
    const screen = window.screen;

    if (screen.width > 640) {
      screenh = "500px";
      screenw = "400px";
    } else {
      screenh = "100%";
      screenw = "100%";
    }
    const enableScrollbar = screen.width < 640;
    var bcWidgetFrame = document.getElementById("bcwidget_frame");
    bcWidgetFrame.width = screenw;
    bcWidgetFrame.height = screenh;
    document.body.style.cssText = enableScrollbar
      ? "position: fixed; top:0; bottom:0; right:0; left:0; overflow: hidden;"
      : "";

    e.source.postMessage(
      {
        type: "bc_animateLoading"
      },
      e.origin
    );
  }
  bidclips_initialised = true;
}

// A function to process messages received by the window.
function receiveMessage(e) {
  console.log("message receiveMessage:: ", e);
  // Check to make sure that this message came from the correct domain.
  // if (e.origin !== "https://widget.bidclips.ml"){ return; }
  // if (e.origin !== "http://localhost:3000"){ return; }

  const screen = window.screen;
  // var smicon = "64px"
  // var smiconWidth = "300px"
  var screenh = "90%";
  var screenw = "400px";
  if (screen.width > 640) {
    screenh = "90%";
    screenw = "400px";
  } else {
    screenh = "100%";
    screenw = "100%";
  }

  var bcWidgetFrame = document.getElementById("bcwidget_frame");
  // let height= smicon;
  // let width = smiconWidth;
  const enableScrollbar = screen.width < 640;

  var reqType = e.data && e.data.type;

  switch (reqType) {
    case "bci_init":
      initBidClips(e);
      break;    
    case "bci_setWindowState":
      var reqValue = e.data && e.data.value;
      console.log('reqValue :: checking : ', reqValue)
      switch (reqValue) {
        case "customHeight":
          var windowStyle = e.data && e.data.style;
          console.log('user want to custom customHeight', windowStyle);
          bcWidgetFrame.style.height = `calc(${windowStyle.height} + 25px)`;
          bcWidgetFrame.style.width = screenw;
          if (screen.width < 640) {
            bcWidgetFrame.style.bottom = "0px";
            bcWidgetFrame.style.right = "0px";
          }
          break;
        case "customStyle":          
          console.log('user want to custom style');
          // bcWidgetFrame.className = "customStyle";
          bcWidgetFrame.style = "";
          bcWidgetFrame.style.width = "100%";
          bcWidgetFrame.height = "";
          break;
        case "open":
          // bcWidgetFrame.width = screenw;
          // bcWidgetFrame.height = screenh;
          bcWidgetFrame.style.height = screenh
          bcWidgetFrame.style.width = screenw
          bcWidgetFrame.style.display = "block";
          document.body.style.cssText = enableScrollbar ? "position: fixed; top:0; bottom:0; right:0; left:0; overflow: hidden;" : "";
          if (screen.width < 640) {
            bcWidgetFrame.style.bottom = "0px";
            bcWidgetFrame.style.right = "0px";
          } 
          // else {
          //   screenh = "100%";
          //   screenw = "100%";
          // }
          if(!existingMetaData){
            const viewPorts = document.getElementsByName("viewport");
            let isExistViewPort = false;
            viewPorts.forEach(port => {
              const content = port.getAttribute("content");
              if(content.includes("initial-scale")){
                existingMetaData = document.createElement("meta");
                const attributesValues = Object.values(port.attributes);
                attributesValues.forEach(valueObj => {
                  existingMetaData.setAttribute(valueObj.name,valueObj.value);
                });
                const splitedContent = content.split(",");
                const maxScaleContentIndex = splitedContent.findIndex(scontent => scontent.includes("maximum-scale"));
                maxScaleContentIndex != -1 ? splitedContent[maxScaleContentIndex] = " maximum-scale=1.0" : splitedContent.push(" maximum-scale=1.0");
                const newContent = splitedContent.join(",");
                console.log('newContent :: check : ', newContent);
                port.setAttribute("content", newContent);
                isExistViewPort = true;
              }
            });
            if(!isExistViewPort){
              const metaData = document.createElement("meta");
              metaData.setAttribute("name","viewport");
              metaData.setAttribute("content","width=device-width, initial-scale=1.0, maximum-scale=1.0");
              metaData.setAttribute("id","bidClipsMetaData");
              document.head.appendChild(metaData);
              existingMetaData = metaData;
            }
          }
          break;
        case "expand":
          // bcWidgetFrame.width = "100%";
          // bcWidgetFrame.height = "100%";
          bcWidgetFrame.style.height = "100%"
          bcWidgetFrame.style.width = "100%"
          bcWidgetFrame.style.right = "0px";
          bcWidgetFrame.style.bottom = "0px";
          bcWidgetFrame.style.display = "block";
          break;
        case "hide":
          bcWidgetFrame.style.display = "none";
          break;

        default:
          // "minimized":
          // bcWidgetFrame.width = "300px";
          // bcWidgetFrame.height = "64px";
          bcWidgetFrame.style.height = "64px";
          bcWidgetFrame.style.width = "300px";
          bcWidgetFrame.style.position = "fixed";
          bcWidgetFrame.style.bottom = "10px";
          bcWidgetFrame.style.right = "10px";
          bcWidgetFrame.style.zIndex = 9999;
          document.body.style.cssText = enableScrollbar ? "" : "";
          bcWidgetFrame.style.display = "block";
          console.log('existingMetaData :: ', existingMetaData)
          if(existingMetaData){
            const id = existingMetaData.getAttribute("id");
            if(id){
              existingMetaData.remove();
            }else{
              const allViewPorts = document.getElementsByName("viewport");
              allViewPorts.forEach(port => {
                const content = port.getAttribute("content");
                if(content.includes("maximum-scale") && content.includes("initial-scale")){
                  const attributesValues = Object.values(port.attributes);
                  attributesValues.forEach(valObj => {
                    port.setAttribute(valObj.name,existingMetaData.getAttribute(valObj.name));
                  });
                }
              });
            }
            existingMetaData=null;
          }
          break;
      }
      break;
    case "bci_getOperations":
      console.log("sending back operations ");
      if (bcWidgetFrame) {
        e.source.postMessage(
          {
            type: "bci_getOperations",
            value: bcWidgetFrame.getAttribute("operations")
          },
          e.origin
        );

      }
      break;
    case "bci_getHref":
      console.log("sending back href ", window.location.href);
      let contentLink = window.location.href;
      if (window.location.href.includes("#bcwidget=")) {
        try {
          let parts = window.location.href.split("#bcwidget=");
          let toEncodeString = atob(parts[1]);
          contentLink = parts[0] + "#bcwidget=" + toEncodeString;
          changeLocation(contentLink);
        } catch (e) {
          contentLink = window.location.href
        }
      }
			
      e.source.postMessage(
        {
          type: "bc_getHref",
          value: contentLink
        },
        e.origin
      );
      break;
    case "bci_getWidgetId":
      console.log(
        "widgetId id from bidclips_integration BIREN ",
        bcWidgetFrame.src
      );
      e.source.postMessage(
        {
          type: "bc_getWidgetId",
          value: bcWidgetFrame.src
        },
        e.origin
      );
      break;
    case "bci_set_localstorage":
      const prop2Store = e.data.propName;
      const val2Store = e.data.propValue;
      if(val2Store === undefined || val2Store === null){
        //this is request for removal of the property
        localStorage.removeItem(`bc_${prop2Store}`);
      }
      else{
        //set update the value of prop2Store
        localStorage.setItem(`bc_${prop2Store}`, val2Store);
      }
      break;
    case "bci_read_localstorage":
      const prop2Read = e.data.propName;
      const valFromStore = localStorage.getItem(`bc_${prop2Read}`);
      e.source.postMessage({
        type : "bc_read_localstorage",
        propValue : valFromStore,
        propName : prop2Read
      },e.origin);
      break;
    case "bc_operationCallaback":
      console.log("bc_operationCallaback received :", e);
      if (e.data && e.data.value && e.data.value.callbackMethodName && typeof window[e.data.value.callbackMethodName] === "function") {
        console.log(
          "bc_operationCallaback applying callback :",
          e.data.value.response
        );
        window[e.data.value.callbackMethodName](e.data.value.response);
        // console.log('print the data::', window[e.data.value.callbackMethodName](e.data.value.response));
      }
      break;
    default:
      console.log("doing nothing for ", e);
      break;
  }
}

function bcPostMessage(operation, config, callbackMethodName) {
  const bcWidgetFrame = document.getElementById("bcwidget_frame");
  bcWidgetFrame.contentWindow.postMessage({
    type: "operation",
    operation: operation,
    config: config,
    callbackMethodName: callbackMethodName
  }, "*");
}

function handleHeightChange() {
  var isReady = window.BidClipsWidget.ready();
  console.log('widget dynamic height', isReady, isAutoResize);
  if (isReady === true) {
    bcPostMessage("onHeightChange", isAutoResize);
  } else {
    setTimeout(() => handleHeightChange(), 2000);
  }
}

function sendDataToBidclipsWidget(eventName, data) {
  var isReady = window.BidClipsWidget.ready();
  console.log('sendDataToBidclipsWidget check', isReady, eventName, data);
  if (isReady === true) {
    bcPostMessage(eventName, data);
  } else {
    setTimeout(() => sendDataToBidclipsWidget(eventName, data), 2500);
  }
}

function hashChange(e) {
  console.log("hashChange :: ", e, window);
  bcPostMessage("hashChangeReloadEvent"); // window.location.reload();
  setTimeout(() => {
    handleHeightChange();
  }, 1000);
};
function changeLocation(locationHash) {
  var newurl = locationHash ;
  window.history.pushState({}, "", newurl);
}

window.bidclipsWidgetRun = bcPostMessage;
window.addEventListener("message", receiveMessage);
window.addEventListener("hashchange", hashChange);

// window.addEventListener("hashchange", hashchange(), false);

function _bidClipsWidgetApi() {
  var isWidgetReady = false;
  const callBackPrefix = "_bc_callback_";
  const emptyMethod = (eventData) => { };
  this[callBackPrefix + "widgetReadyState"] = (isReady) => {
    isWidgetReady = isReady;
  };
  window[callBackPrefix + "changeService"] = emptyMethod;
  window[callBackPrefix + "submitServiceRequest"] = emptyMethod;
  return {
    searchShopService: (value) => new Promise((resolve, reject) => {
      // We call resolve(...) when what we were doing asynchronously was successful, and reject(...) when it failed.
      // In this example, we use setTimeout(...) to simulate async code.
      // In reality, you will probably be using something like XHR or an HTML5 API.
      const cllbackMethodName = callBackPrefix + new Date().getTime();
      window[cllbackMethodName] = resolve;
      bcPostMessage("searchShopService", value, cllbackMethodName)
    }),
    createServiceRequest: (serviceId) => bcPostMessage("navigation", { event: `/bid/${serviceId}/shopService` }),
    viewBidInformation: (bidId) => bcPostMessage("navigation", { event: `/quote/${bidId}` }),
    /**
     * 
     * @param {*} id dbId
     * @param {*} status "bidId", "bidMessageId"
     */
    viewCommunicationMessages: (id, status) => bcPostMessage("navigation", { event: `/communication/${id}/${status}` }),
    /**
     * 
     * @param {*} event open, close, extends, hide
     */
    open: () => bcPostMessage('widget', { "event": "open" }),
    hide: () => bcPostMessage('widget', { "event": "hide" }),
    widget: (event) => bcPostMessage("widget", { event }),
    header: (isShow = true) => bcPostMessage("setHeader", { 'setHeader': isShow }),
    footer: (isShow = true) => bcPostMessage("setFooter", { 'setFooter': isShow }),
    menu: (isShow = true) => bcPostMessage("setMenu", { 'setMenu': isShow }),
    on: (eventName, callback, data) => {
      if (eventName === "onHeightChange") {
        isAutoResize = true;
        handleHeightChange();
      }
      this[callBackPrefix + eventName] = callback;
      if(data){
        sendDataToBidclipsWidget(eventName, data);
      }
    },
    remove: (eventName, callback) => {
      if (eventName === "onHeightChange") {
        isAutoResize = false;
        handleHeightChange();
      }
      this[callBackPrefix + eventName] = emptyMethod;
    },
    showWindowSizeButtons: (isShow = true) => sendDataToBidclipsWidget("setShowWindowSizeButton", { 'setShowWindowSizeButton': isShow }),
    showHowTo: (isShow = true) => sendDataToBidclipsWidget("setHowTo", { 'setHowTo': isShow }),
    showServiceSearchArea: (isShow = true) => sendDataToBidclipsWidget("setServiceAreaSearch", { 'setServiceAreaSearch': isShow }),
    showMainServiceArea: (isShow = true) => sendDataToBidclipsWidget("setMainSearchArea", { 'setMainSearchArea': isShow }),
    showPromotedServiceArea: (isShow = true) => sendDataToBidclipsWidget("setPromotedServiceArea", { 'setPromotedServiceArea': isShow }),
    ready: () => {
      return isWidgetReady;
    },    
  }  
}

// initialized widget api
window.BidClipsWidget = _bidClipsWidgetApi();
